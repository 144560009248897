import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { animated } from "react-spring";
import { Squash as Hamburger } from "hamburger-react";
import styled from "styled-components";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import useBoop from "../lib/boop";
import { FaChevronDown, FaShoppingCart } from "react-icons/fa";
import Logo from "../images/svg/fastrrr_logo_color.inline.svg";

import Badge from "@mui/material/Badge";
import { useCartStore } from "../lib/store";

interface Props {
  scrolled: boolean;
  allowTransparentHeader?: boolean;
}

const Header: React.FC<Props> = (props) => {
  const { cartItemCount } = useCartStore();

  // stupid hack to force rerender on mount otherwise badge is only shown after route change, thanks @mui badge
  const [key, forceUpdate] = useState<number>(0);
  useEffect(() => {
    forceUpdate(Date.now());
  }, []);

  const { scrolled, allowTransparentHeader } = props;

  const [productMenuOpen, setProductMenuOpen] = useState<boolean>(false);
  const [aboutMenuOpen, setAboutMenuOpen] = useState<boolean>(false);
  const [hamburgerOpen, setHamburgerOpen] = useState<boolean>(false);

  const [opaque, setOpaque] = useState<boolean>(false);

  const [productChevronStyle, productChevronTrigger] = useBoop({ y: 2 });
  const [aboutChevronStyle, aboutChevronTrigger] = useBoop({ y: 2 });

  useEffect(() => {
    if (!allowTransparentHeader) {
      setOpaque(true);
    } else {
      if (scrolled || productMenuOpen || aboutMenuOpen || hamburgerOpen) {
        setOpaque(true);
      } else {
        setOpaque(false);
      }
    }
  }, [productMenuOpen, hamburgerOpen, scrolled, aboutMenuOpen]);

  return (
    <ClickAwayListener onClickAway={() => setHamburgerOpen(false)}>
      <NavBar opaque={opaque}>
        <LogoContainer>
          <Link to="/">
            <StyledLogo />
          </Link>
        </LogoContainer>
        <NavBarLinks hamburgerOpen={hamburgerOpen}>
          <ul>
            <li>
              <ClickAwayListener onClickAway={() => setProductMenuOpen(false)}>
                <a
                  onMouseEnter={productChevronTrigger}
                  onClick={() => setProductMenuOpen(!productMenuOpen)}
                >
                  PRODUCTS{" "}
                  <animated.span style={productChevronStyle}>
                    <FaChevronDown />
                  </animated.span>
                </a>
              </ClickAwayListener>
              <DropDown show={productMenuOpen} numberOfItems={4}>
                <Link to="/products/dinghy-edition">Dinghy Edition</Link>
                <Link to="/products/yacht-edition">Yacht Edition</Link>
                <Link to="/products/coach-boat-pro">Coachboat Pro</Link>
                <Link to="/products/data-bay">DataBay</Link>
              </DropDown>
            </li>
            <li>
              <Link to="/services">SERVICES</Link>
            </li>
            <li>
              <ClickAwayListener onClickAway={() => setAboutMenuOpen(false)}>
                <a
                  onMouseEnter={aboutChevronTrigger}
                  onClick={() => setAboutMenuOpen(!aboutMenuOpen)}
                >
                  ABOUT{" "}
                  <animated.span style={aboutChevronStyle}>
                    <FaChevronDown />
                  </animated.span>
                </a>
              </ClickAwayListener>
              <DropDown show={aboutMenuOpen} numberOfItems={2}>
                {/* <Link to="/solutions">Solutions</Link> */}
                <Link to="/data-sources">Data Sources</Link>
                <Link to="/about-us">About us</Link>
              </DropDown>
            </li>
            <li>
              <Link to="/press">PRESS</Link>
            </li>
            <li>
              <Link to="/support">SUPPORT</Link>
            </li>
            <li>
              <Link to="/contact">CONTACT</Link>
            </li>
            <li>
              <Link to="/products">WEBSHOP</Link>
            </li>
            <DesktopCart key={key + 1}>
              <Link to="/cart">
                CART
                <Badge
                  className={"cart-icon"}
                  badgeContent={cartItemCount}
                  color="primary"
                >
                  <FaShoppingCart />
                </Badge>
              </Link>
            </DesktopCart>
            <li>
              <a
                // href={`${process.env.GATSBY_SITE_URL}/databay`}
                href="http://databay.fastrrr.com"
                aria-label="DataBay"
              >
                DATABAY
              </a>
            </li>
          </ul>
        </NavBarLinks>
        <MobileCart key={key + 2}>
          <Link to="/cart">
            <Badge
              className={"cart-icon"}
              badgeContent={cartItemCount}
              color="primary"
            >
              <FaShoppingCart />
            </Badge>
          </Link>
        </MobileCart>
        <HamburgerWrapper>
          <Hamburger
            toggled={hamburgerOpen}
            toggle={setHamburgerOpen}
            label={"Open/Close Menu"}
          />
        </HamburgerWrapper>
      </NavBar>
    </ClickAwayListener>
  );
};

export default Header;

const NavBar = styled.nav<{
  opaque: boolean;
}>`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  display: flex;
  justify-content: space-between;

  background: ${(props) =>
    props.opaque ? `var(--color-dark-blue)` : `rgba(0,0,0,0)`};

  color: #fff;

  transition: 0.2s;
  transition-delay: ${(props) => (props.opaque ? `0s` : `0.2s`)};

  @media (max-width: 1460px) {
    flex-direction: column;
  }

  @media (max-width: 480px) {
    height: 70px;
  }
`;

const LogoContainer = styled.div`
  padding-left: 32px;
  height: 100px;
  @media (max-width: 1460px) {
    align-self: flex-start;
  }
  @media (max-width: 480px) {
    height: 70px;
    padding-left: 16px;
  }
`;

const StyledLogo = styled(Logo)`
  height: 50px;
  margin: 25px 0;
  width: auto;
  fill: #fff;
  @media (max-width: 480px) {
    height: 36px;
    margin: 17px 0;
  }
`;

const NavBarLinks = styled.div<{
  hamburgerOpen: boolean;
}>`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform-origin: top center;

  & ul {
    display: flex;
    margin: 0;
    padding: 0;
  }
  & li {
    position: relative;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & span {
    margin: 3px 0 0 3px;
    display: "block";
  }
  & li > a {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem;
    text-decoration: none;
    color: #fff;
    &:hover {
      color: var(--color-orange);
      transition: 0.2s;
    }
  }

  // databay button style
  @media (min-width: 1461px) {
    & li:last-child a {
      line-height: 2rem;
      padding: 1.2rem 2.2rem;
      background: #fff;
      color: var(--color-orange);
      border-radius: 10px;
      border: 1px solid #fff;
      transition: 0.2s;
      &:hover {
        background: #fff0;
        color: #fff;
      }
    }
  }

  @media (max-width: 1460px) {
    align-items: flex-start;
    height: auto;

    background: var(--color-dark-blue);

    transform: ${(props) => (props.hamburgerOpen ? `scaleY(1)` : `scaleY(0)`)};
    transition: transform 0.2s;
    transition-delay: ${(props) => (props.hamburgerOpen ? `0.2s` : `0s`)};

    & ul {
      flex-direction: column;
      width: 100%;
    }
    & ul li {
      flex-direction: column;
      padding-left: 32px;
      width: 100%;
      align-items: flex-start;

      &::after {
        content: "";
        display: block;
        width: calc(100% - 32px);
        height: 0.5px;
        background: #414050;
      }
    }
    & li > a {
      height: 40px;
      line-height: 40px;
      margin: 0;
    }
  }
`;

const DesktopCart = styled.li`
  .cart-icon {
    display: block;
    width: 25px;
    height: 25px;
    margin: 0;
    margin-left: 10px;
    transform: translate(0px, -1px);

    .MuiBadge-badge {
      background-color: var(--color-orange);
    }

    & svg {
      width: 25px;
      height: 25px;
    }

    @media (max-width: 1460px) {
      width: 22px;
      height: 22px;
      transform: translate(0px, -3px);
      & svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  @media (max-width: 1460px) {
    display: none !important;
  }
`;

const MobileCart = styled.div`
  position: absolute;
  top: 0;
  right: 80px;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none;
    color: white;
    &:hover {
      color: var(--color-orange);
    }
  }
  @media (min-width: 1461px) {
    display: none;
  }
  @media (max-width: 480px) {
    height: 70px;
    width: 70px;
  }
  .cart-icon {
    display: block;
    margin: 0;
    .MuiBadge-badge {
      background-color: var(--color-orange);
    }

    @media (max-width: 1460px) {
      transform: translate(5px, 5px);
      & svg {
        width: 25px;
        height: 25px;
      }
    }
  }
`;

const DropDown = styled.div<{ show: boolean; numberOfItems: number }>`
  position: absolute;
  width: 240px;
  top: 92px;
  left: 50%;
  transform-origin: top left;
  transform: ${(props) =>
    props.show ? `scaleY(1) translateX(-50%)` : `scaleY(0) translateX(-50%)`};
  transition: 0.2s;

  background: var(--color-dark-blue);

  & a {
    display: block;
    height: 50px;
    line-height: 50px;
    padding: 0 1.8rem 0 2.8rem;
    text-decoration: none;
    color: #fff;
    &::after {
      position: absolute;
      left: ${(240 - 200) / 2}px;
      content: "";
      display: block;
      width: 200px;
      height: 0.5px;
      background: #414050;
    }

    &:last-child {
      border-bottom: 0;
    }
    &:hover {
      color: var(--color-orange);
      background: var(--color-middle-blue);
      transition: 0.2s;
    }
  }

  @media (max-width: 1460px) {
    position: relative;
    height: ${(props) =>
      props.show ? `${props.numberOfItems * 40}px` : "0px"}; // 4x40px
    top: 0;
    left: 50%;
    width: 100%;
    transition: 0.2s;
    & a {
      height: 40px;
      line-height: 40px;
      padding-left: 32px;
      &:hover {
        background: inherit;
      }

      &::after {
        width: calc(100% - 32px);
        transform: translateX(-20px);
      }
    }
  }
`;

const HamburgerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1461px) {
    display: none;
  }
  @media (max-width: 480px) {
    height: 70px;
    width: 70px;
  }
`;
