import React from "react";
import { SpringValue, useSpring } from "react-spring";
// Source here: https://joshwcomeau.com/snippets/react-hooks/use-prefers-reduced-motion
import usePrefersReducedMotion from "./prefersReducedMotion";

interface BoopProps {
  x?: number;
  y?: number;
  rotation?: number;
  scale?: number;
  timing?: number;
  springConfig?: {
    tension?: number;
    friction?: number;
  };
}

const useBoop: (
  props: BoopProps
) => [{ transform: SpringValue<string> } | { transform: "none" }, () => void] =
  (props) => {
    const defaultProps = {
      x: 0,
      y: 0,
      rotation: 0,
      scale: 1,
      timing: 150,
      springConfig: {
        tension: 300,
        friction: 10,
      },
    };

    const options = { ...defaultProps, ...props };
    const prefersReducedMotion = usePrefersReducedMotion();

    const [isBooped, setIsBooped] = React.useState(false);
    const style = useSpring({
      transform: isBooped
        ? `translate(${options.x}px, ${options.y}px)
         rotate(${options.rotation}deg)
         scale(${options.scale})`
        : `translate(0px, 0px)
         rotate(0deg)
         scale(1)`,
      config: options.springConfig,
    });

    React.useEffect(() => {
      if (!isBooped) {
        return;
      }
      const timeoutId = window.setTimeout(() => {
        setIsBooped(false);
      }, options.timing);
      return () => {
        window.clearTimeout(timeoutId);
      };
    }, [isBooped]);
    const trigger = React.useCallback(() => {
      setIsBooped(true);
    }, []);

    const nostyle: { transform: "none" } = { transform: "none" };
    const appliedStyle = prefersReducedMotion ? nostyle : style;
    return [appliedStyle, trigger];
  };
export default useBoop;
