import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import useInView from "react-cool-inview";
import styled from "styled-components";
import CookieConsent from "react-cookie-consent";
import Header from "./header";
import { GlobalStyle } from "../styles/ui.styles";
import Footer from "./footer";

interface Props {
  children: React.ReactNode;
  allowTransparentHeader?: boolean;
}

const Layout: React.FC<Props> = ({ children, allowTransparentHeader }) => {
  const { observe, inView } = useInView({});
  const [scrolled, setScrolled] = useState<boolean>(false);

  useEffect(() => {
    setScrolled(!inView);
  }, [inView]);

  return (
    <>
      <GlobalStyle />
      <Trigger ref={observe} />
      <Header
        scrolled={scrolled}
        allowTransparentHeader={
          allowTransparentHeader ? allowTransparentHeader : false
        }
      />
      <Main>{children}</Main>
      {/* <button onClick={handleClick}>Show snackbar</button> */}
      <Footer />
      <CookieConsentWrapper>
        <CookieConsent
          disableStyles={true}
          enableDeclineButton
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics"
          buttonClasses="button"
          containerClasses="container"
          contentClasses="content"
          buttonWrapperClasses="button-wrapper"
          declineButtonClasses="button-decline"
        >
          <p>
            FASTRRR International uses cookies to provide its online services
            and to improve user experience. Please read our{" "}
            <Link to={"/privacy-policy"}>Privacy Policy</Link> before you
            continue.
          </p>
        </CookieConsent>
      </CookieConsentWrapper>
    </>
  );
};

export default Layout;

const Main = styled.main`
  width: 100%;
`;

const Trigger = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  pointer-events: none;
`;

const CookieConsentWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  .container {
    background: var(--color-dark-blue);
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }
  .content {
    /* flex: 1 1 300px; */
    flex: 1;
    p {
      color: #fff;
      margin: 0;
    }
    p,
    a {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .button-wrapper {
    padding-left: 16px;
    display: grid;
    place-items: center;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
  }

  button {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    min-width: 18px;
    height: 44px;
    padding: 0 32px;
    font-size: 16px;
    text-decoration: none;
    color: #fff;
    background: var(--color-orange);
    border: 0;
    cursor: pointer;
    &:hover {
      background: var(--color-orange-hovered);
    }
    transition: 0.2s;
  }

  .button-decline {
    color: #fff;
    background: rgba(0, 0, 0, 0);
    border: 2px solid #fff;
    &:hover {
      color: #fff9;
      background: #fff0;
      border-color: #fff9;
    }
  }

  @media (max-width: 593px) {
    .container {
      flex-direction: column;
    }
    .button-wrapper {
      padding: 16px 0 0 0;
    }
  }
`;
