import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import Logo from "../images/svg/fastrrr_logo_color.inline.svg";

const Footer: React.FC = () => {
  return (
    <Outer>
      <Inner>
        <Top>
          <Left>
            <StyledLogo />
            <p>
              Fastrrr is a smart and quick - performance analysis solution
              brought to you by a team of sailors.
            </p>
            <Socials>
              <div>
                <a
                  href="https://www.facebook.com/fastrrr"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookF />
                </a>
              </div>

              <div>
                <a
                  href="https://www.instagram.com/fastrrr/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
              </div>
              <div>
                <a
                  href="https://www.linkedin.com/company/fastrrr-science-behind-sailing/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedinIn />
                </a>
              </div>
            </Socials>
          </Left>
          <Right>
            <div>
              <p>Products</p>
              <Link to="/products/dinghy-edition">DinghyEdition</Link>
              <Link to="/products/yacht-edition">YachtEdition</Link>
              <Link to="/products/coach-boat-pro">CoachBoatPro</Link>
              <Link to="/products/data-bay">Databay</Link>
            </div>
            <div>
              <p>Fastrrr</p>
              <Link to="/about-us">About Us</Link>
              <Link to="/products">Webshop</Link>
              <Link to="/data-sources">Data Sources</Link>
              <Link to="/press">Press</Link>
            </div>
            <div>
              <p>Legal</p>
              <Link to="/imprint">Imprint</Link>
              <Link to="/terms-and-conditions">
                Genereal Terms <br />
                and Conditions
              </Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
          </Right>
        </Top>
        <Bottom>
          <p>Fastrrr &copy; {new Date().getFullYear()} All rights reserved</p>
          <div>
            <Link to="/support">Support</Link>
            <Link to="/contact">Contact</Link>
          </div>
        </Bottom>
      </Inner>
      <Sponsors>
        <div>
          <StaticImage
            width={340}
            src={"../images/hiventures.png"}
            alt={"Hiventures"}
          />
        </div>
        <div>
          <StaticImage
            width={360}
            src={"../images/EUblokk.png"}
            alt={"Széchenyi 2020"}
          />
        </div>
      </Sponsors>
    </Outer>
  );
};

export default Footer;

const Outer = styled.div`
  position: relative;
  width: 100%;
  background: var(--color-dark-blue);
  color: #fff;
`;

const Inner = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 32px var(--gutter) 0 var(--gutter);

  a {
    color: #fff;
    text-decoration: underline;
  }
`;

const Top = styled.div`
  width: 100%;
  display: flex;

  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }

  p {
    margin: 3.4rem 0;
  }
`;

const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--color-light-grey);

  padding-top: 40px;

  div {
    display: flex;
    align-items: center;
  }
  a:last-child {
    margin-left: 5rem;
  }

  flex-direction: column-reverse;
  align-items: center;

  p {
    margin-bottom: 60px;
  }
  a {
    margin-bottom: 26px;
  }

  @media (min-width: 620px) {
    flex-direction: row;
    p {
      margin-top: 0;
      margin-bottom: 26px;
    }
  }
`;

const Left = styled.div`
  max-width: 600px;
  @media (min-width: 993px) {
    padding-right: 16px;
  }
`;

const StyledLogo = styled(Logo)`
  height: 70px;
  width: auto;
  fill: #fff;
`;

const Right = styled.div`
  display: grid;

  p {
    color: var(--color-orange);
    font-weight: 600;
    margin-bottom: 3rem;
    margin-top: 0;
  }
  a {
    display: block;
    margin-bottom: 1.4rem;
  }

  div {
    margin-bottom: 46px;
  }

  grid-template-rows: repeat(3, 1fr);

  @media (min-width: 480px) {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(3, 1fr);
    p {
      margin-top: 3.4rem;
      margin-bottom: 5rem;
    }
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 180px);
  }
`;

const Socials = styled.div`
  display: flex;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 30px;
    text-decoration: none;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin: 0 24px 60px 0;
    border: 1px solid var(--color-light-grey);
    box-sizing: border-box;
    border-radius: 6px;
  }
  svg {
    width: 18px;
    height: 18px;
    color: #fff;
  }
`;

const Sponsors = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  flex-direction: column;
  height: auto;

  div:first-child {
    align-self: center;
  }

  @media (min-width: 620px) {
    flex-direction: row;
    align-items: flex-end;
    height: 260px;

    div:first-child {
      align-self: flex-end;
    }
  }
`;
