import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

export const GlobalStyle = createGlobalStyle`

  ${normalize}

  :root{
    --color-text: #000;
    --color-background: #1A192C;
    --color-orange: #F8331C;
    --color-light-blue: #130E66;
    --color-middle-blue: #282845;
    --color-dark-blue: #1A192C;
    --color-light-grey: #DEDEDE;
    --color-middle-grey: #AAAAAA;
    --color-dark-grey: #616161;

    --color-orange-hovered: rgba(248, 51, 28, 0.7);

    --gutter: 16px;
    @media (min-width: 480px) {
      --gutter: 32px;
    }
    @media (min-width: 992px) {
      --gutter: 64px;
    }

    --shadow:
    0.4px 0.8px 1.7px rgba(0, 0, 0, 0.022),
    1px 2px 4px rgba(0, 0, 0, 0.032),
    1.9px 3.8px 7.5px rgba(0, 0, 0, 0.04),
    3.4px 6.7px 13.4px rgba(0, 0, 0, 0.048),
    6.3px 12.5px 25.1px rgba(0, 0, 0, 0.058),
    15px 30px 60px rgba(0, 0, 0, 0.08);

    min-height: 1vw;


  }
  *, *:before, *:after {
    box-sizing: border-box;
    font-family: Montserrat, Arial, 'Open Sans',"Helvetica Neue", Helvetica, sans-serif;
  }

  html, body {
    max-width: 100%;
    overflow-x: hidden;
  }

  html {
    font-size: 62.5%;   // 1rem = 10px
  }

  body {
    background: var(---color-background);
    color: var(--color-text);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  // * fluid typography calculator: https://websemantics.uk/tools/responsive-font-calculator/

  h1 {
    font-size: 3.5rem;
    @media (min-width: 480px) {
    font-size: calc(3.5rem + ((1vw - 0.48rem) * 4.8611));
    min-height: 0vw;
  }
  @media (min-width: 1200px) {
    font-size: 7rem;
  }
    min-height: 0vw;
    font-weight: 600;
  }
  h2 {
    font-size: 2.5rem;
    @media (min-width: 480px) {
    font-size: calc(2.5rem + ((1vw - 0.48rem) * 2.7778));
    min-height: 0vw;
  }
  @media (min-width: 1200px) {
    font-size: 4.5rem;
  }
    min-height: 0vw;
    font-weight: 600;
  }

  h3 {
    font-size: 2rem;
    @media (min-width: 480px) {
    font-size: calc(2rem + ((1vw - 0.48rem) * 2.2222));
    min-height: 0vw;
  }
  @media (min-width: 1200px) {
    font-size: 3.6rem;
  }
    min-height: 0vw;
    
    font-weight: 600;
  }

  h4 {
    font-size: 1.8rem;
    @media (min-width: 480px) {
    font-size: calc(1.8rem + ((1vw - 0.48rem) * 0.8333));
    min-height: 0vw;
  }
  @media (min-width: 1200px) {
    font-size: 2.4rem;
  }
    min-height: 0vw;
    font-weight: 600;
  }

  h5 {
    font-size: 1.6rem;
    @media (min-width: 480px) {
    font-size:  calc(1.6rem + ((1vw - 0.48rem) * 0.5556));
    min-height: 0vw;
  }
  @media (min-width: 1200px) {
    font-size: 2rem;
  }
    min-height: 0vw;
    color: var(--color-dark-grey);
    font-weight: 600;
  }

  p, a, input, li, label, textarea, select {
    font-size: 1.6rem;
    @media (min-width: 480px) {
    font-size: calc(1.6rem + ((1vw - 0.48rem) * 0.5556));
    min-height: 0vw;
  }
  @media (min-width: 1200px) {
    font-size: 2rem;
  }
  line-height: 2.8rem;
  @media (min-width: 480px) {
    line-height: calc(2.8rem + ((1vw - 0.48rem) * 1.1111));
    min-height: 0vw;
  }
  @media (min-width: 1200px) {
    line-height: 3.6rem;
  }
  min-height: 0vw;
  }

  small {
    font-size: 1.6rem;
    color: var(--color-dark-grey);
  }

  label {
    font-weight: 600;
  }

  a {
    text-decoration: underline;
    color: var(--color-orange);
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      color: var(--color-orange-hovered);
    }
  }

  code {
    display: inline-block;
    padding: 0.5rem 1rem;
    margin-left: 10px;
    background: var(--color-orange);
    color: #fff;
    border-radius: 6px;
  }

  /* styling for snackbar */
  .snackbar {
    background-color: #F8331C !important;
    font-size: 1.5rem !important;
  }


`;

export const HeaderPlaceholder = styled.div`
  width: 100%;
  height: 100px;
  background: var(--color-dark-blue);
  @media (max-width: 480px) {
    height: 70px;
  }
`;

export const Button = styled.button<{
  primary?: boolean;
  // available?: boolean; // if used as an add to cart button, can be disabled
}>`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  min-width: 18px;
  height: 44px;
  padding: 0 32px;
  font-size: 1.9rem;
  color: ${(props) =>
    props.primary ? `rgb(255,255,255)` : `var(--color-orange)`};
  background: ${(props) =>
    props.primary ? `var(--color-orange)` : `rgba(0,0,0,0)`};
  border: ${(props) => (props.primary ? `0` : `2px solid var(--color-orange)`)};
  cursor: pointer;
  &:hover {
    color: ${(props) =>
      props.primary ? `rgb(255,255,255)` : `var(--color-orange-hovered)`};
    background: ${(props) =>
      props.primary ? `var(--color-orange-hovered)` : `rgba(0,0,0,0)`};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  transition: 0.2s;
`;

export const PaginationButton = styled.button<{ primary?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 50px;
  height: 50px;
  background: ${(props) =>
    props.primary ? `var(--color-orange)` : `rgba(0,0,0,0)`};
  border: ${(props) => (props.primary ? `0` : `2px solid var(--color-orange)`)};
  border-radius: 999px;
  cursor: pointer;
  transition: 0.2s;
  svg {
    width: 26px;
    height: 26px;
    fill: ${(props) =>
      props.primary ? `rgb(255,255,255)` : `var(--color-orange)`};
    transition: 0.2s;
  }
  &:hover {
    border: ${(props) =>
      props.primary ? `0` : `2px solid var(--color-orange-hovered)`};
    background: ${(props) =>
      props.primary ? `var(--color-orange-hovered)` : `rgba(0,0,0,0)`};
    transition: 0.2s;
    svg {
      fill: ${(props) =>
        props.primary ? `rgb(255,255,255)` : `var(--color-orange-hovered)`};
      transition: 0.2s;
    }
  }
`;

export const Underline = styled.div`
  width: 200px;
  height: 5px;
  background: var(--color-orange);
`;

interface DoubleTitleProps {
  normalTitle: string;
  largeTitle: string;
}

export const DoubleTitle: React.FC<DoubleTitleProps> = (props) => {
  const { normalTitle, largeTitle } = props;

  return (
    <DoubleTitleWrapper>
      <XLText>{largeTitle}</XLText>
      <h2>{normalTitle}</h2>
    </DoubleTitleWrapper>
  );
};
const DoubleTitleWrapper = styled.div`
  text-align: center;
  position: relative;
  margin: 120px 0;
`;
const XLText = styled.h1`
  white-space: nowrap;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  font-size: min(16vw, 20rem);
  font-weight: 800;
  color: #fff0;
  margin: 0;
  user-select: none;
  -webkit-text-stroke: 2px var(--color-orange-hovered);

  @media (max-width: 992px) {
    -webkit-text-stroke: 1px var(--color-orange-hovered);
  }
`;

export const UnavailableOverlay: React.FC = () => (
  <div
    style={{
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      background: "#fff9",
      textAlign: "center",
      color: "#888",
    }}
  >
    <h3>
      Currently
      <br />
      Unavailable
    </h3>
  </div>
);
