import create, { SetState, GetState } from "zustand";
import { devtools, persist } from "zustand/middleware";

import products from "../../content/products/products.json";

interface CartState {
  withHardware: boolean;
  hardware: Hardware | null;
  software: Software | null;
  addons: Addon[];
  cartItemCount: number;

  useTypeDefault: UseType; // this provides the default switch state, but the local switch state will be used when adding items to the cart
  setUseTypeDefault: (useType: UseType) => void;

  setWithHardware: (selection: boolean) => void;
  setSoftware: (softwareId: string | null) => void; // add selected package to cart (w or w/ hardware)

  update: () => void; // helper function to add/remove hardware when the withhardware checkbox changes

  addAddon: (id: string) => void;
  removeAddon: (id: string) => void;
  incrementAddon: (id: string) => void;
  decrementAddon: (id: string) => void;
  clearCart: () => void;
}

const cartStore: (
  set: SetState<CartState>,
  get: GetState<CartState>
) => CartState = (set, get) => ({
  withHardware: true,
  hardware: null,
  software: null,
  addons: [],
  cartItemCount: 0,

  useTypeDefault: "leisure",

  setUseTypeDefault: (useType) => {
    set((state: CartState) => ({ ...state, useTypeDefault: useType }));
    get().update();
  },

  setWithHardware: (selection) => {
    set((state: CartState) => ({ ...state, withHardware: selection }));
    get().update();
  },

  //clear package selection
  setSoftware: (softwareId) => {
    if (softwareId) {
      set((state: CartState) => ({ ...state, software: { id: softwareId } }));
      get().update();
    } else {
      set((state: CartState) => ({ ...state, software: null, hardware: null }));
      get().update();
    }
  },

  update: () => {
    set((state: CartState) => {
      // if the software was selected but hardware was missing
      if (state.software) {
        const hardware = state.withHardware
          ? { id: findCorrespondingHardwareId(state.software.id) }
          : null;
        return { ...state, hardware };
      } else {
        return { ...state };
      }
    });

    // * delete anything that is unavailable (might be saved to the users browser localstorage, but not available at checkout time)
    // * run this update function on the cart page and main page on load
    // check software validity
    set((state: CartState) => {
      if (
        products.find((node) => node.id === state.software?.id)?.available ===
        false
      ) {
        return {
          ...state,
          software: null,
          hardware: null,
          withHardware: false,
        };
      } else {
        return { ...state };
      }
    });
    // check hardware validity
    set((state: CartState) => {
      if (state.software) {
        let hardware = state.withHardware
          ? { id: findCorrespondingHardwareId(state.software.id) }
          : null;
        if (
          products.find((node) => node.id === hardware?.id)?.available === false
        ) {
          hardware = null;
        }
        return { ...state, hardware };
      } else {
        return { ...state };
      }
    });
    // check addons validity
    set((state: CartState) => {
      const validAddons = state.addons.filter(
        (addon) => products.find((prod) => prod.id === addon.id)?.available
      );
      return { ...state, addons: validAddons };
    });

    // update number of items in cart
    set((state: CartState) => {
      let count = 0;
      state.software && count++;
      state.hardware && count++;
      state.addons.forEach((addon) => (count += addon.quantity));
      return { ...state, cartItemCount: count };
    });
  },

  addAddon: (id) => {
    set((state: CartState) => {
      const isPresent = state.addons.find((addons) => addons.id === id);
      if (!isPresent) {
        state.addons.push({ id, quantity: 1 });
      } else {
        const updatedAddons = state.addons.map((addon) =>
          addon.id === id ? { id, quantity: addon.quantity + 1 } : addon
        );
        return { ...state, addons: updatedAddons };
      }
    });
    get().update();
  },
  removeAddon: (id) => {
    set((state: CartState) => {
      const isPresent = state.addons.find((addons) => addons.id === id);
      if (!isPresent) {
        return state;
      } else {
        const updatedAddons = state.addons.filter((addon) => addon.id !== id);
        return { ...state, addons: updatedAddons };
      }
    });
    get().update();
  },
  incrementAddon: (id) => {
    set((state: CartState) => {
      const isPresent = state.addons.find((addons) => addons.id === id);
      if (!isPresent) {
        return state;
      } else {
        const updatedAddons = state.addons.map((addon) =>
          addon.id === id ? { id, quantity: addon.quantity + 1 } : addon
        );
        return { ...state, addons: updatedAddons };
      }
    });
    get().update();
  },
  decrementAddon: (id) => {
    set((state: CartState) => {
      const isPresent = state.addons.find((addons) => addons.id === id);
      if (!isPresent) {
        return state;
      } else {
        const updatedAddons = state.addons.map((addon) =>
          addon.id === id
            ? { id, quantity: Math.max(addon.quantity - 1, 0) }
            : addon
        );
        return { ...state, addons: updatedAddons };
      }
    });
    get().update();
  },

  clearCart: () => {
    set((state: CartState) => ({
      ...state,
      hardware: null,
      software: null,
      addons: [],
    }));
    get().update();
  },
});

export const useCartStore = create(
  devtools(persist(cartStore, { name: "cart" }))
);

// * when the software package is updated, the hardware is automatically changed
export const findCorrespondingHardwareId: (softwareId: string) => string = (
  softwareId
) => {
  const software = products.find((product) => product.id === softwareId);
  const matchingHardware = products.find(
    (product) =>
      product?.useType === software?.useType &&
      product?.boatType === software?.boatType
  );
  return matchingHardware?.id ?? "";
};

// * helper function to find software package id
export const findSoftwareId: (
  useType: UseType,
  boatType: BoatType,
  period: Period
) => string = (useType, boatType, period) => {
  const found = products.find(
    (product) =>
      product.useType === useType &&
      product.boatType === boatType &&
      product.period === period
  );
  return found?.id ? found.id : "";
};

export const findHardwareId: (useType: UseType, boatType: BoatType) => string =
  (useType, boatType) => {
    const found = products.find(
      (product) =>
        product.useType === useType &&
        product.boatType === boatType &&
        product.productType === "hardware"
    );
    return found?.id ?? "";
  };

// * helper function to find availabbility
export const isAvailable: (
  useType: UseType,
  boatType: BoatType,
  period: Period
) => boolean = (useType, boatType, period) => {
  const found = products.find(
    (product) =>
      product.useType === useType &&
      product.boatType === boatType &&
      product.period === period
  );
  return found?.available ?? false;
};
